import storage from 'store';
// import wx from "weixin-js-sdk";
// import { getusertokenbytemporarycode } from "../api/resident";

const ua = navigator.userAgent;

const android = ua.match(new RegExp('(Android);?[\\s/]+([\\d.]+)?'));
const ipad = ua.match(new RegExp('(iPad).*OS\\s([\\d_]+)'));
const ipod = ua.match(new RegExp('(iPod)(.*OS\\s([\\d_]+))?'));
const iphone = !ipad && ua.match(new RegExp('(iPhone\\sOS)\\s([\\d_]+)'));

// 方便pc端调试初始化数据
if (!android && !ipad && !ipod && !iphone) {
  storage.set('auth-token-pc', '4b83c5de97f8210c0e3cc0d23ea90cd0');
  storage.set('scan-code-pc', 'xxxxxxxxxx');
  storage.set('geo-info-pc', {
    longitudeMsg: 116.683165,
    latitudeMsg: 37.2084569991045,
    locationMsg: '德城区政务中心',
  });
  storage.set('emit-certification-pc', '0');
  storage.set('emit-bio-assay-pc', '0');
}

export const DEVICE = {
  isAndroid: android,
  isIos: iphone || ipad || ipod,
  isWeixin: ua.match(new RegExp('MicroMessenger', 'i')),
  osVersion: (() => {
    let v = '';
    if (android) {
      return android[2];
      // androidChrome = ua.toLowerCase().indexOf("chrome") >= 0;
    } else if (iphone && !ipod) {
      v = iphone[2].replace(/_/g, '.');
    } else if (ipad) {
      v = ipad[2].replace(/_/g, '.');
    } else if (ipod) {
      v = ipod[3] ? ipod[3].replace(/_/g, '.') : null;
    }
    // iOS 8+ changed UA
    v &&
      ua.indexOf('Version/') >= 0 &&
      v.split('.')[0] === '10' &&
      (v = ua.toLowerCase().split('version/')[1].split(' ')[0]);
    return v;
  })(),
  // Check for status bar and fullscreen app mode
  statusBar:
    window.innerWidth * window.innerHeight === screen.width * screen.height,
  pixelRatio: window.devicePixelRatio || 1,
};

// 场景H5交互获取token
export const getNativeToken = async () => {
  let status = true;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  // console.log(window.location,params, "window.location.search");

  let type = false;
  let authToken = '';

  if (params.devicetype == 3) {
    type = true;

    if (storage.get('app-tempcode') == params.tempcode) {
      authToken = storage.get('Auth-Token-subsidies');
    } else {
      storage.set('app-tempcode', params.tempcode);
      params.tempcode &&
        (await fetch('/api/mbapi/outh/login/getusertokenbytemporarycode', {
          method: 'post',
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
          },
          body: JSON.stringify({ code: params.tempcode }),
        })
          .then((response) => response.json())
          .then(({ data }) => {
            authToken = data;
          })
          .catch(() => {
            console.error('授权码已失效请重新访问');
            status = false;
          }));
    }
  }
  if (type) {
    authToken &&
      setTimeout(() => {
        window.CallBackTokenInfo &&
          window.CallBackTokenInfo({
            token: authToken,
          });
      }, 0);
  } else {
    if (DEVICE.isAndroid) {
      try {
        window.cityApp.userTokenInfo();
      } catch (e) {
        status = false;
        console.error('【getDeviceToken】异常--', e);
      }
    } else if (DEVICE.isIos) {
      try {
        window.webkit.messageHandlers.userTokenInfo.postMessage('');
      } catch (e) {
        status = false;
        console.error('【getDeviceToken】异常--', e);
      }
    } else {
      setTimeout(() => {
        window.CallBackTokenInfo &&
          window.CallBackTokenInfo({
            token: storage.get('auth-token-pc'),
          });
      }, 0);
    }
  }
  return status
    ? new Promise((resolve, reject) => {
        const fn = window.CallBackTokenInfo;
        window.CallBackTokenInfo = (res = {}) => {
          fn && fn(res);
          const { token } = res;
          token ? resolve(token) : reject(res);
        };
      })
    : Promise.reject('【getDeviceToken】异常');
};

// 场景H5调取原生扫码并回调
export const emitNativeScanCode = () => {
  let status = true;
  if (DEVICE.isAndroid) {
    try {
      window.cityApp.scanCodeSys('ok');
    } catch (e) {
      status = false;
      console.error('【emitNativeScanCode】异常--', e);
    }
  } else if (DEVICE.isIos) {
    try {
      window.webkit.messageHandlers.scanCodeSys.postMessage('ok');
    } catch (e) {
      status = false;
      console.error('【emitNativeScanCode】异常--', e);
    }
  } else {
    setTimeout(() => {
      window.CallBackScanCode &&
        window.CallBackScanCode({
          scanCode: storage.get('scan-code-pc'),
        });
    }, 0);
  }
  return status
    ? new Promise((resolve, reject) => {
        const fn = window.CallBackScanCode;
        window.CallBackScanCode = (res = {}) => {
          fn && fn(res);
          const { scanCode } = res;
          scanCode ? resolve(scanCode) : reject(res);
        };
      })
    : Promise.reject('【emitNativeScanCode】异常');
};

// 场景H5获取实时经纬度交互
export const getNativeGeo = () => {
  let status = true;
  if (DEVICE.isAndroid) {
    try {
      window.cityApp.locationMessageSys();
    } catch (e) {
      status = false;
      console.error('【getNativeGeo】异常--', e);
    }
  } else if (DEVICE.isIos) {
    try {
      window.webkit.messageHandlers.locationMsgSys.postMessage('');
    } catch (e) {
      status = false;
      console.error('【getNativeGeo】异常--', e);
    }
  } else {
    setTimeout(() => {
      window.CallBackLocationMsg &&
        window.CallBackLocationMsg(storage.get('geo-info-pc'));
    }, 0);
  }
  return status
    ? new Promise((resolve, reject) => {
        const fn = window.CallBackLocationMsg;
        window.CallBackLocationMsg = (res = {}) => {
          fn && fn(res);
          const { longitudeMsg, latitudeMsg, locationMsg } = res;
          longitudeMsg || latitudeMsg || locationMsg
            ? resolve({
                longitude: longitudeMsg,
                latitude: latitudeMsg,
                location: locationMsg,
              })
            : reject(res);
        };
      })
    : Promise.reject('【getNativeGeo】异常');
};

// 场景H5唤起实名认证
export const emitNativeCertification = () => {
  let status = true;
  if (DEVICE.isAndroid) {
    try {
      window.cityApp.userAuth();
    } catch (e) {
      status = false;
      console.error('【emitNativeCertification】异常--', e);
    }
  } else if (DEVICE.isIos) {
    try {
      window.webkit.messageHandlers.userAuth.postMessage('');
    } catch (e) {
      status = false;
      console.error('【emitNativeCertification】异常--', e);
    }
  } else {
    setTimeout(() => {
      window.CallBackUserAuthMsg &&
        window.CallBackUserAuthMsg({
          authStatus: storage.get('emit-certification-pc'),
        });
    }, 0);
  }
  return status
    ? new Promise((resolve, reject) => {
        const fn = window.CallBackUserAuthMsg;
        window.CallBackUserAuthMsg = (res = {}) => {
          fn && fn(res);
          const { authStatus } = res;
          authStatus == '0' ? resolve(res) : reject(res);
        };
      })
    : Promise.reject('【emitNativeCertification】异常');
};

// 场景H5唤起活体检测
export const emitNativeBioAssay = () => {
  let status = true;
  if (DEVICE.isAndroid) {
    try {
      window.cityApp.liveBody();
    } catch (e) {
      status = false;
      console.error('【emitNativeBioAssay】异常--', e);
    }
  } else if (DEVICE.isIos) {
    try {
      window.webkit.messageHandlers.liveBody.postMessage('');
    } catch (e) {
      status = false;
      console.error('【emitNativeBioAssay】异常--', e);
    }
  } else {
    setTimeout(() => {
      window.CallBackLiveBodyMsg &&
        window.CallBackLiveBodyMsg({
          liveStatus: storage.get('emit-bio-assay-pc'),
        });
    }, 0);
  }
  return status
    ? new Promise((resolve, reject) => {
        const fn = window.CallBackLiveBodyMsg;
        window.CallBackLiveBodyMsg = (res = {}) => {
          fn && fn(res);
          const { liveStatus } = res;
          liveStatus == '0' ? resolve(res) : reject(res);
        };
      })
    : Promise.reject('【emitNativeBioAssay】异常');
};
