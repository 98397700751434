import storage from 'store';
import {
  login,
  getCommunityandUserInfo,
  getTokenByTempCode,
} from '@/api/login';
import {
  ACCESS_TOKEN,
  ACCESS_R_TOKEN,
  AUTH_TOKEN,
  COMMUNITY_ID,
  COMMUNITY_NAME,
  GROUP_ID,
} from '@/store/mutation-types';
import { getNativeToken } from '@/utils/tools';
import utils from '@/utils/utils.js';

const user = {
  namespaced: true,
  state: {
    token: '',
    refreshToken: '',
    authToken: '',
    communityid: '',
    communityname: '',
    userInfo: {
      auth_flag: '',
      avatar: '',
      idcard: '',
      mobile: '',
      mobiletoken: '',
      secensid: '',
      sex: '',
      source_from: '',
      token: '',
      username: '',
    },
  },
  mutations: {
    //app 用户token
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_R_TOKEN: (state, refreshToken) => {
      state.refreshToken = refreshToken;
    },
    //用户token
    AUTH_TOKEN: (state, authToken) => {
      state.authToken = authToken;
    },
    SET_COMMUNITY_ID: (state, communityid) => {
      state.communityid = communityid;
      storage.set(COMMUNITY_ID, communityid);
    },
    SET_COMMUNITY_NAME: (state, communityname) => {
      state.communityname = communityname;
      storage.set(COMMUNITY_NAME, communityname);
    },
    SET_GROUP_ID: (state, groupid) => {
      state.groupid = groupid;
      storage.set(GROUP_ID, groupid);
    },
    SET_USER_INFO: (state, user) => {
      state.userInfo = user;
    },
  },
  // replace
  actions: {
    async getAuthToken({ commit, state, dispatch }) {
      // 1395bc143a4484236cb539b9414c17f5
      // let authtoken = 'ef5f9105-3f75-4274-9ec2-9c769855f06d';
      let authtoken = state.authToken;
      if (!authtoken) {
        await getNativeToken()
          .then((t) => {
            authtoken = t;
            console.log(authtoken);
          })
          .catch((e) => console.error('【store.getAuthToken】', e));
        if (authtoken) {
          if (storage.get(AUTH_TOKEN) != authtoken) {
            await dispatch('Logout');
          }
          storage.set(AUTH_TOKEN, authtoken);
          commit('AUTH_TOKEN', authtoken);
        }
      }
      return authtoken
        ? Promise.resolve(authtoken)
        : Promise.reject('未获取到authtoken');
    },
    // 登录
    async Login({ commit, dispatch }) {
      // const authtoken = await dispatch('getAuthToken').catch((e) =>
      //   console.error('【store.Login】', e)
      // );
      // return authtoken
      //   ?
      let tempcode = utils.getUrlKey('tempcode');
      storage.set('TempCode', tempcode);
      new Promise((resolve, reject) => {
        getTokenByTempCode({
          temptoken: tempcode,
          // temptoken: '438f9134-8cfb-4395-9c2b-9286704ed984',
        })
          .then(({ result }) => {
            const {
              access_token,
              refresh_token,
              // createtime,
              expires_in,
              // secensid,
            } = result;
            const offtime = expires_in
              ? Number(expires_in) * 1000
              : 7 * 24 * 60 * 60 * 1000;
            storage.set(ACCESS_TOKEN, access_token, offtime);
            storage.set(ACCESS_R_TOKEN, refresh_token, offtime);
            commit('SET_TOKEN', access_token);
            commit('SET_R_TOKEN', refresh_token);
            dispatch('getUserInfo');
            resolve(access_token);
          })
          .catch((error) => {
            reject(error);
          });
      });
      // : Promise.reject('无authToken！');
    },
    // 获取用户信息
    getUserInfo({ commit }) {
      getCommunityandUserInfo()
        .then(({ result }) => {
          console.log(result);
          storage.set('UserName', result.username);
          storage.set('mobile', result.mobile);
          storage.set('Idcard', result.idcard);
          commit('SET_USER_INFO', result);
        })
        .catch((error) => {});
    },
    // 登出
    Logout({ commit }) {
      return new Promise((resolve) => {
        // logout(state.token).finally(() => {
        commit('SET_TOKEN', '');
        commit('SET_R_TOKEN', '');
        commit('AUTH_TOKEN', '');
        commit('SET_COMMUNITY_ID', '');
        storage.remove(ACCESS_TOKEN);
        storage.remove(ACCESS_R_TOKEN);
        storage.remove(AUTH_TOKEN);
        storage.remove('UserName');
        storage.remove('Idcard');
        storage.remove('mobile');
        resolve();
        // });
      });
    },
  },
};

export default user;
