/* eslint-disable */
export default {
  getUrlKey: function (name) {
    return (
      decodeURIComponent((new RegExp(name + "=" + "([^&;]+?)(&|#|;|$)").exec(location.href) || [, ""])[1].replace(/\+/g, "%20")) ||
      decodeURIComponent(
        (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(location.href) || [, ""])[1].replace(/\+/g, "%20")
      ) ||
      false
    );
  },
};
