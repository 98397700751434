import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import {
  Button,
  NavBar,
  Search,
  Icon,
  Calendar,
  Cell,
  ActionSheet,
  DatetimePicker,
  Field,
  Form,
  RadioGroup,
  Radio,
  Popup,
  ShareSheet,
  Checkbox,
  CheckboxGroup,
  Uploader,
  Picker,
  Notify,
  Toast,
  Swipe,
  SwipeItem,
  DropdownMenu,
  DropdownItem,
  PullRefresh,
  List,
  Lazyload,
  Tab,
  Tabs,
  Progress,
  Dialog,
  Sticky,
  Cascader,
  NumberKeyboard,
} from 'vant';
import 'vant/lib/index.css';
Vue.use(Cascader);
Vue.use(NumberKeyboard);
Vue.use(Toast);
Vue.use(Notify);
Vue.use(Form);
Vue.use(Picker);
Vue.use(Uploader);
Vue.use(Popup);
Vue.use(ShareSheet);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Field);
Vue.use(DatetimePicker);
Vue.use(ActionSheet);
Vue.use(Calendar);
Vue.use(Button);
Vue.use(NavBar);
Vue.use(Search);
Vue.use(Icon);
Vue.use(Cell);
Vue.use(Picker);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Lazyload);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Progress);
Vue.use(Dialog);
Vue.use(Sticky);

import AMap from 'vue-amap';
Vue.use(AMap);

// 初始化vue-amap
AMap.initAMapApiLoader({
  // 高德key
  key: 'ce7bb9aa42cdbeaeb1c1a82f452069c4',
  // 插件集合 （插件按需引入）
  plugin: [
    'AMap.Autocomplete',
    'AMap.PlaceSearch',
    'AMap.Scale',
    'AMap.OverView',
    'AMap.ToolBar',
    'AMap.MapType',
    'AMap.PolyEditor',
    'AMap.CircleEditor',
    'AMap.Geolocation',
  ],
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.4',
});

Vue.config.productionTip = false;
// import Vconsole from 'vconsole';
// let vConsole = new Vconsole();

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
