import request from '@/utils/request';
import qs from 'qs';

const userApi = {
  // 场景登录，改成场景对应的登录接口
  Login: '/api/witcommuntiy-platform/login',
};

const contentType = {
  form: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
  json: { 'Content-Type': 'application/json;charset=UTF-8' },
};

export const Api = userApi;

/**
 * login func
 * parameter: {
 *     authtoken: '', //客户端处获取
 * }
 * @param parameter
 * @returns {*}
 */
export function login(parameter) {
  return request({
    url: userApi.Login,
    method: 'post',
    headers: contentType.form,
    data: qs.stringify(parameter),
  });
}

//通过临时码登录
export function getTokenByTempCode(temptoken) {
  return request({
    url: '/api/login/loginbytempcode',
    method: 'post',
    headers: contentType.form,
    data: qs.stringify(temptoken),
  });
}

//获取社区及用户信息
export function getCommunityandUserInfo(tempcode) {
  return request({
    url: '/api/epi/user/getuserinfo',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}
