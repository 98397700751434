// community换成对应仓库名，唯一值即可
export const ACCESS_TOKEN = 'Access-Token-community';
export const ACCESS_R_TOKEN = 'Refresh-Token-community';
export const AUTH_TOKEN = 'Auth-Token-community';
export const COMMUNITY_ID = 'Community-id';
export const COMMUNITY_NAME = 'Community-name';
export const USER_NAME = 'username';

export const GROUP_ID = 'Group-id';

export const TEMPCODE = 'Tempcode';
