import Vue from 'vue';
import VueRouter from 'vue-router';
import community from '../views/index.vue';

Vue.use(VueRouter);
// 1
const routes = [
  {
    path: '/',
    name: 'community',
    component: community,
    meta: {
      keepAlive: true, // 需要缓存
    },
  },
  {
    path: '/FdtbForm',
    name: 'FdtbForm',
    component: () => import('../views/FdtbForm.vue'),
  },
  {
    path: '/JjjcSee',
    name: 'JjjcSee',
    component: () => import('../views/JjjcSee.vue'),
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});

export default router;
