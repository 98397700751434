import axios from "axios";
import store from "@/store";
import storage from "store";
import {
	ACCESS_TOKEN,
	COMMUNITY_ID
} from "@/store/mutation-types";
import { Api } from "@/api/login.js";

export const builder = (data, message, code = 0, date) => {
	const responseBody = {
		success: true,
		message: "",
		timestamp: 0,
		result: null,
		code: 0,
	};
	responseBody.result = data;
	if (message !== undefined && message !== null) {
		responseBody.message = message;
	}
	if (code !== undefined && code !== 0) {
		responseBody.code = code;
	}
	responseBody.timestamp = date || new Date().getTime();
	return responseBody;
};

const resHandler = (response) => {
	const D = response.data || {};
	const {
		success,
		message,
		code,
		date,
		data,
		...others
	} = D;
	let msg = message;
	let status = code || response.status;
	let result = data;
	if (!msg && response.message) {
		const temp = response.message.match(new RegExp("(\\scode\\s)(\\d+)$", "g"));
		const tempCode = temp && temp[0];
		tempCode &&
			(status = tempCode.replace(new RegExp("\\D+", "g"), "")) &&
			(msg = response.message);
	}!msg && response.status == 200 && (msg = response.statusText);
	(typeof result === "undefined" || (!result && isNaN(Number(result)))) &&
	(result = others);
	const res = Object.assign({}, builder(result, msg, status, date), {
		success,
	});
	return {
		success,
		res,
		config: response.config
	};
};

// 创建 axios 实例
const instance = axios.create({
	baseURL: "",
	timeout: 6000,
});

// 异常拦截处理器
const errorHandler = async (error = {}) => {
	const {
		res
	} = resHandler(error);
	const {
		code
	} = res;
	const token = storage.get(ACCESS_TOKEN);
	const isLogin = Api.Login === (error.config || {}).url;
	
	
	if (code === 403 && !isLogin) {
		
		
		await store
			.dispatch("user/Login")
			.catch((e) => console.error("【interceptors.response.errorHandler】", e));
		if (storage.get(ACCESS_TOKEN) && error.config) {
			return instance(error.config);
		}
	}
	if (code === 401) {
		if (token) {
			store.dispatch("Logout").then(() => {
				setTimeout(() => {
					window.location.reload();
				}, 1500);
			});
		}
	}
	return Promise.reject(res);
};

// request interceptor
instance.interceptors.request.use(async (config) => {
	const token = storage.get(ACCESS_TOKEN);
	const communityid = storage.get(COMMUNITY_ID);

	if (token) {
		config.headers["Access-Token"] = token;
		config.headers["token"] = token;
	}

	if (communityid) {
		config.headers["communityid"] = communityid;
	}
	
	return config;
}, errorHandler);

// response interceptor
instance.interceptors.response.use((response) => {
	const {
		success,
		res
	} = resHandler(response);
	if (success) {
		
		return res;
	} else {
		return errorHandler(response);
	}
}, errorHandler);

export default instance;

export {
	instance as request
};
