<template>
  <div class="main column">
    <!-- <p>请选择所需的应用</p> -->
    <div class="FdtbToBtn" @click="FdtbToBtn">
      <img :src="FdsbIcon" alt="" />
      <div>
        <b class="Title">返德申报</b>
        <span>></span>
      </div>
    </div>
    <div class="JjjcToBtn" @click="JjjcToBtn">
      <img :src="JjjcIcon" alt="" />
      <div>
        <b class="Title">居家监测</b>
        <span>></span>
      </div>
    </div>
  </div>
</template>

<script>
  import { Toast } from 'vant';

  const BASE_OSS_URL =
    process.env.NODE_ENV === 'production'
      ? window.location.origin
      : 'http://58.58.133.136:8508';

  // import {
  // 	getTokenByTempCode,
  // 	addMicroAppLog,
  // 	addFunctiveLog
  // } from "../api/tenement"

  import { getTokenByTempCode, getCommunityandUserInfo } from '../api/login';

  import storage from 'store';

  import { mapActions, mapState, mapMutations } from 'vuex';

  import { TEMPCODE, GROUP_ID } from '@/store/mutation-types';

  export default {
    data() {
      return {
        FdsbIcon: require('@/assets/FdsbIcon.svg'),
        JjjcIcon: require('@/assets/JjjcIcon.svg'),
      };
    },
    computed: {
      ...mapState('user', ['token', 'groupid']),
      // banner高度
      scrollerHeight: function () {
        return (document.body.clientWidth - 28) / 2.3 + 'px';
      },
    },
    created() {
      if (!this.token) {
        this.$store
          .dispatch('user/Login')
          .then(() => {})
          .catch(() => {
            Toast('获取用户失败');
          });
      }
    },
    activated() {},
    methods: {
      ...mapMutations('user', ['SET_GROUP_ID']),
      getToken(tempcode) {
        getTokenByTempCode(tempcode)
          .then((data) => {
            this.$store.commit('streetUser/SET_COMMUNITY_TOKEN', data.result);
          })
          .catch(() => {
            Toast('获取用户失败');
          });
      },
      FdtbToBtn() {
        this.$router.push({
          name: 'FdtbForm',
          query: {},
        });
      },
      JjjcToBtn() {
        this.$router.push({
          name: 'JjjcSee',
          query: {},
        });
      },
    },
  };
</script>

<style lang="less" scoped>
  @import '../style/modal.less';
  @import '../style/index.less';
  .main {
    width: 100%;
    height: auto;
    background: #fafcfd;
  }
  // p {
  //   font-family: 'PingFang SC Medium';
  //   font-weight: 500;
  //   font-size: 14px;
  //   text-align: center;
  //   color: #575757;
  //   margin: 6rem 0 0 0;
  // }
  img {
    width: 15%;
    height: 40%;
    margin: 0 0 0 1.5rem;
  }
  .Title {
    font-family: 'PingFang SC Semibold';
    font-weight: 600;
    font-size: 18px;
    text-align: left;
    color: #1c1c1c;
  }
  .FdtbToBtn {
    width: 96%;
    height: 132px;
    display: flex;
    align-items: center;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0 0px 12px rgba(0, 0, 0, 0.06);
    margin: 3rem 0 1rem 2%;
    div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 0 0 0 1.5rem;
      span {
        width: 7px;
        height: 10px;
        margin: 0 1rem 0 0;
      }
    }
  }
  .JjjcToBtn {
    width: 96%;
    height: 132px;
    margin: 0 0 0 2%;
    display: flex;
    align-items: center;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0 0px 12px rgba(0, 0, 0, 0.06);
    div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 0 0 0 1.5rem;
      span {
        width: 7px;
        height: 10px;
        margin: 0 1rem 0 0;
      }
    }
  }
</style>
